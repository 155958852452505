.ff-character {
  width: 96px; /* Adjust the width and height of the sub-parts as per your requirements */
  height: 96px;
  background-image: url('./ff-characters.png'); /* Replace with the path to your PNG file */
  background-repeat: no-repeat;
}

#who-00 {background-position: -0px -0px;}
#who-01 {background-position: -96px -0px;}
#who-02 {background-position: -192px -0px;}
#who-03 {background-position: -288px -0px;}
#who-04 {background-position: -384px -0px;}
#who-05 {background-position: -480px -0px;}
#who-06 {background-position: -576px -0px;}
#who-07 {background-position: -672px -0px;}
#who-08 {background-position: -768px -0px;}
#who-09 {background-position: -864px -0px;}
#who-10 {background-position: -0px -96px;}
#who-11 {background-position: -96px -96px;}
#who-12 {background-position: -192px -96px;}
#who-13 {background-position: -288px -96px;}
#who-14 {background-position: -384px -96px;}
#who-15 {background-position: -480px -96px;}
#who-16 {background-position: -576px -96px;}
#who-17 {background-position: -672px -96px;}
#who-18 {background-position: -768px -96px;}
#who-19 {background-position: -864px -96px;}
#who-20 {background-position: -0px -192px;}
#who-21 {background-position: -96px -192px;}
#who-22 {background-position: -192px -192px;}
#who-23 {background-position: -288px -192px;}
#who-24 {background-position: -384px -192px;}
#who-25 {background-position: -480px -192px;}
#who-26 {background-position: -576px -192px;}
#who-27 {background-position: -672px -192px;}
#who-28 {background-position: -768px -192px;}
#who-29 {background-position: -864px -192px;}
#who-30 {background-position: -0px -288px;}
#who-31 {background-position: -96px -288px;}
#who-32 {background-position: -192px -288px;}
#who-33 {background-position: -288px -288px;}
#who-34 {background-position: -384px -288px;}
#who-35 {background-position: -480px -288px;}
#who-36 {background-position: -576px -288px;}
#who-37 {background-position: -672px -288px;}
#who-38 {background-position: -768px -288px;}
#who-39 {background-position: -864px -288px;}
#who-40 {background-position: -0px -384px;}
#who-41 {background-position: -96px -384px;}
#who-42 {background-position: -192px -384px;}
#who-43 {background-position: -288px -384px;}
#who-44 {background-position: -384px -384px;}
#who-45 {background-position: -480px -384px;}
#who-46 {background-position: -576px -384px;}
#who-47 {background-position: -672px -384px;}
#who-48 {background-position: -768px -384px;}
#who-49 {background-position: -864px -384px;}
#who-50 {background-position: -0px -480px;}
#who-51 {background-position: -96px -480px;}
#who-52 {background-position: -192px -480px;}
#who-53 {background-position: -288px -480px;}
#who-54 {background-position: -384px -480px;}
#who-55 {background-position: -480px -480px;}
#who-56 {background-position: -576px -480px;}
#who-57 {background-position: -672px -480px;}
#who-58 {background-position: -768px -480px;}
#who-59 {background-position: -864px -480px;}
#who-60 {background-position: -0px -576px;}
#who-61 {background-position: -96px -576px;}
#who-62 {background-position: -192px -576px;}
#who-63 {background-position: -288px -576px;}
#who-64 {background-position: -384px -576px;}
#who-65 {background-position: -480px -576px;}
#who-66 {background-position: -576px -576px;}
#who-67 {background-position: -672px -576px;}
#who-68 {background-position: -768px -576px;}
#who-69 {background-position: -864px -576px;}
#who-70 {background-position: -0px -672px;}
#who-71 {background-position: -96px -672px;}
#who-72 {background-position: -192px -672px;}
#who-73 {background-position: -288px -672px;}
#who-74 {background-position: -384px -672px;}
#who-75 {background-position: -480px -672px;}
#who-76 {background-position: -576px -672px;}
#who-77 {background-position: -672px -672px;}
#who-78 {background-position: -768px -672px;}
#who-79 {background-position: -864px -672px;}
#who-80 {background-position: -0px -768px;}
#who-81 {background-position: -96px -768px;}
#who-82 {background-position: -192px -768px;}
#who-83 {background-position: -288px -768px;}
#who-84 {background-position: -384px -768px;}
#who-85 {background-position: -480px -768px;}
#who-86 {background-position: -576px -768px;}
#who-87 {background-position: -672px -768px;}
#who-88 {background-position: -768px -768px;}
#who-89 {background-position: -864px -768px;}
#who-90 {background-position: -0px -864px;}
#who-91 {background-position: -96px -864px;}
#who-92 {background-position: -192px -864px;}
#who-93 {background-position: -288px -864px;}
#who-94 {background-position: -384px -864px;}
#who-95 {background-position: -480px -864px;}
#who-96 {background-position: -576px -864px;}
#who-97 {background-position: -672px -864px;}
#who-98 {background-position: -768px -864px;}
#who-99 {background-position: -864px -864px;}
