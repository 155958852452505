body {
  /* font-family: 'Goudy Old Style', cursive; */
  /* font-family: 'Uncial Antiqua', cursive; */
  font-family: 'Lombardic Capitals', cursive;

  background-color: #f0f0f0;
  background-image: url('images/worldmap.jpg');
  animation: scrollBackground 120s linear infinite;
}

@keyframes scrollBackground {
  100% {
    background-position-x: 150%;
  }
}

.Main-container {
  height: calc(100vh - 60px);
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 0px;
}

.App {
  text-align: center;
  margin: auto;
  /* width: 30%; */
  flex-basis: 30%
}

.app-block {
  display: flex;
  flex-direction: column;
  font-size: calc(5px + 2vmin);
}

.app-block>p {
  margin: 0.1rem 0;
}

.app-block>a>img {
  height: 15vmin;
  cursor: pointer;
  /* pointer-events: none; */
}

.app-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-link>p {
  margin: 0px;
}

.app-feature-list {
  list-style-type: none;
  margin-top: 0;
  padding-left: 0;
  font-size: calc(2px + 2vmin);
}

.jump {
  animation: jump 0.3s linear infinite;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.walk {
  animation: walk 2s linear infinite;
  /* animation-delay: calc(var(--delay) * -1s); */
}

@keyframes walk {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0);
  }
}

.status-container {
  height: calc(100vh - 300px);
  width: calc(100vw - 300px);
  margin: 150px;
  /* display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 30px 15%; */
}

/* walk around the screen in circling group */

.group-container {
  height: 96px;
  width: 96px;
  margin: 50px;
  /* display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 30px 15%; */
}

.icon0 {
  position: absolute;
  animation: circle0 30s linear infinite;
}
.icon1 {
  position: absolute;
  animation: circle1 30s linear infinite;
}
.icon2 {
  position: absolute;
  animation: circle2 30s linear infinite;
}
.icon3 {
  position: absolute;
  animation: circle3 30s linear infinite;
}

@keyframes circle0 {
  0% {
    transform: rotate(0deg) translateX(50px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(50px) rotate(-360deg);
  }
}
@keyframes circle1 {
  0% {
    transform: rotate(90deg) translateX(50px) rotate(-90deg);
  }
  100% {
    transform: rotate(450deg) translateX(50px) rotate(-450deg);
  }
}
@keyframes circle2 {
  0% {
    transform: rotate(180deg) translateX(50px) rotate(-180deg);
  }
  100% {
    transform: rotate(540deg) translateX(50px) rotate(-540deg);
  }
}
@keyframes circle3 {
  0% {
    transform: rotate(270deg) translateX(50px) rotate(-270deg);
  }
  100% {
    transform: rotate(630deg) translateX(50px) rotate(-630deg);
  }
}

.moving-group {
  position: absolute;
  animation: moveGroup 120s linear infinite;
}

@keyframes moveGroup {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: calc(100vw - 50px - 50px - 96px ); /* Move to the right edge */
  }
  50% {
    top: calc(100vh - 50px - 50px - 96px ); /* Move to the bottom-right edge */
    left: calc(100vw - 50px - 50px - 96px );
  }
  75% {
    top: calc(100vh - 50px - 50px - 96px ); /* Move to the bottom-left edge */
    left: 0;
  }
  100% {
    top: 0; /* Move back to the top-left edge */
    left: 0;
  }
}